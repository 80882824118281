import React from "react";

const PlayerInfoModal = ({ playerInfo, setModalPlayerInfo }) => {
  return (
    <div
      style={{
        position: "fixed",
        width: `${playerInfo ? "100vw" : "0vw"}`,
        height: "100vh",
        backgroundColor: "black",
        zIndex: "1000",
        background: "rgba(0, 0, 0, 0.8)",
        transition: ".4s",
      }}
    >
      {playerInfo && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2vh 5vw",
          }}
        >
          <div>
            <img
              src={require(`../../../images/players/${playerInfo.name
                .toLowerCase()
                .replace(/\s/g, "")}.jpg`)}
              className="player-image-circular"
              alt="image"
              style={{
                height: "10em",
                width: "10em",
              }}
            />
          </div>
          <div
            style={{ color: "white", fontSize: "25px", fontWeight: "700" }}
            onClick={() => setModalPlayerInfo(null)}
          >
            X
          </div>
        </div>
      )}
    </div>
  );
};

export default PlayerInfoModal;
