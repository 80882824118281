export const playerInfo = [
  {
    year: "2022",
    players: [
      {
        name: "Matt Ausloos",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0,
        onBasePercentage: 0,
        sluggingPercentage: 0,
        onBasePlusSluggingPercentage: 0,
      },
      {
        name: "Andrew Bruggink",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0,
        onBasePercentage: 0,
        sluggingPercentage: 0,
        onBasePlusSluggingPercentage: 0,
      },
      {
        name: "Elijah Goodman",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Jason Fuchs",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Josh Serio",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Kelly Goodman",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Avery Goodman",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Steve Nelsen",
        games: 0,
        bats: "L",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Jim Fuchs",
        games: 0,
        atBats: 0,
        bats: "R",
        throws: "R",
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Rick Steinberg",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Donny Baseball",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Seth Nelsen",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Nick Vavrik",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Dennis Vavrik",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Jon Keane",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Matt Liewen",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Luis Perez",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Eric Solberg",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Mason Pingel",
        games: 0,
        bats: "L",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Luke Ockwood",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
    ],
  },
  {
    year: "2023",
    players: [
      {
        name: "Matt Ausloos",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0,
        onBasePercentage: 0,
        sluggingPercentage: 0,
        onBasePlusSluggingPercentage: 0,
      },
      {
        name: "Elijah Goodman",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Jason Fuchs",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Josh Serio",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Kelly Goodman",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Avery Goodman",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Steve Nelsen",
        games: 0,
        bats: "L",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Jim Fuchs",
        games: 0,
        atBats: 0,
        bats: "R",
        throws: "R",
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Rick Steinberg",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Donny Baseball",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Seth Nelsen",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Nick Vavrik",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Dennis Vavrik",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Jon Keane",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Luke Ockwood",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Mitch (Illegal)",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Austin (Illegal)",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Jake (Illegal)",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Brian (Illegal)",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Connor (Illegal)",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Michael Barnes",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Chris Barnes",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
      {
        name: "Eddie Edwards",
        games: 0,
        bats: "R",
        throws: "R",
        atBats: 0,
        runs: 0,
        hits: 0,
        doubles: 0,
        triples: 0,
        homeruns: 0,
        runsBattedIn: 0,
        baseOnBalls: 0,
        strikeouts: 0,
        sac: 0,
        average: 0.0,
        onBasePercentage: 0.0,
        sluggingPercentage: 0.0,
        onBasePlusSluggingPercentage: 0.0,
      },
    ],
  },
];
