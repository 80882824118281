import React from "react";
import { playerInfo } from "../../constants/playerInfo";
import PlayerInfoModal from "../Modals/PlayerInfoModal/PlayerInfoModal";
import "./Roster.css";

function Roster({ setModalPlayerInfo }) {
  const [selectedYear, setSelectedYear] = React.useState("2023");
  const [players, setPlayers] = React.useState([]);

  React.useEffect(() => {
    const playersThatYear = JSON.parse(
      JSON.stringify(
        playerInfo.find((info) => info.year === selectedYear).players
      )
    );
    setPlayers(playersThatYear);
  }, [selectedYear]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <select
        id="year"
        value={selectedYear}
        onChange={(e) => setSelectedYear(e.target.value)}
        style={{
          width: "75px",
          borderRadius: "5px",
          marginLeft: "9%",
          marginLeft: "8vw",
          marginTop: "3vh",
          height: "20px",
          fontSize: "15px",
        }}
      >
        <option value="2023">2023</option>
        <option value="2022">2022</option>
      </select>
      <div
        style={{
          width: "100vh",
          display: "flex",
          flexDirection: "column",
          marginTop: "1.5vh",
          marginBottom: "1vh",
          height: "80vh",
          overflow: "scroll",
        }}
      >
        {players.map((player) => (
          <div
            style={{
              display: "flex",
              padding: ".5vh 0vw",
              color: "black",
              borderBottom: ".5px solid grey",
            }}
          >
            <div style={{ width: "30vw" }}>
              <img
                src={require(`../../images/players/${player.name
                  .toLowerCase()
                  .replace(/\s/g, "")}.jpg`)}
                className="player-image-circular"
                alt="image"
                style={{
                  height: "2.8em",
                  width: "2.8em",
                  borderRadius: "50px",
                  marginRight: "4vw",
                }}
              />
            </div>
            <div
              style={{
                padding: "12px",
                width: "40vw",
                textAlign: "left",
                fontWeight: "700",
                fontSize: "15px",
                color: "hsl(203 100% 54%)",
                cursor: "pointer",
              }}
              onClick={() => setModalPlayerInfo(player)}
            >
              {player.name}
            </div>
            <div style={{ padding: "12px", width: "30vw" }}>
              B/T: {player.bats}/{player.throws}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Roster;
