export const gameDates = [
  {
    year: "2022",
    games: [
      {
        date: "May 5th",
        time: "7:00",
        field: 3,
        opponent: "Milliman",
        result: "L 9-21",
        boxScore: [],
        playerStats: [
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 0,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 4,
            runs: 4,
            hits: 4,
            runsBattedIn: 6,
            doubles: 1,
            triples: 0,
            homeruns: 3,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 1,
            hits: 3,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 3,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Seth Nelsen",
            atBats: 3,
            runs: 0,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 3,
            runs: 0,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Rick Steinberg",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "May 12th",
        time: "8:00",
        field: 1,
        opponent: "Sluggos Sluggers",
        result: "W 11-2",
        boxScore: [],
        playerStats: [
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 3,
            runs: 3,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 3,
            hits: 3,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 1,
            hits: 4,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Seth Nelsen",
            atBats: 4,
            runs: 2,
            hits: 0,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 3,
            runs: 1,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 2,
            sac: 0,
          },
          {
            name: "Rick Steinberg",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 2,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 0,
            runs: 0,
            hits: 0,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Jim Fuchs",
            atBats: 1,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "May 19th",
        time: "6:00",
        field: 2,
        opponent: "Pizza Man",
        result: "L 14-21",
        boxScore: [],
        playerStats: [
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 3,
            hits: 2,
            runsBattedIn: 0,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 4,
            runs: 1,
            hits: 1,
            runsBattedIn: 4,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 2,
            hits: 4,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 5,
            doubles: 1,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 4,
            runs: 1,
            hits: 3,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Andrew Bruggink",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jim Fuchs",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 2,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "May 26th",
        time: "8:00",
        field: 1,
        opponent: "Nicholas & Associates",
        result: "L 2-16",
        boxScore: [],
        playerStats: [
          {
            name: "Nick Vavrik",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Josh Serio",
            atBats: 3,
            runs: 0,
            hits: 3,
            runsBattedIn: 0,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 3,
            runs: 0,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Liewen",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "June 2nd",
        time: "7:15",
        field: 2,
        opponent: "Milliman",
        result: "W 15-14",
        boxScore: [],
        playerStats: [
          {
            name: "Josh Serio",
            atBats: 6,
            runs: 1,
            hits: 4,
            runsBattedIn: 2,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 5,
            runs: 2,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 5,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 5,
            runs: 2,
            hits: 3,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Luis Perez",
            atBats: 5,
            runs: 3,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 5,
            runs: 2,
            hits: 4,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 5,
            runs: 0,
            hits: 2,
            runsBattedIn: 3,
            doubles: 0,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Liewen",
            atBats: 5,
            runs: 0,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 5,
            runs: 3,
            hits: 4,
            runsBattedIn: 2,
            doubles: 0,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 4,
            runs: 1,
            hits: 3,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
        ],
      },
      {
        date: "June 9th",
        time: "8:15",
        field: 1,
        opponent: "Brookfield Blasters",
        result: "L 10-13",
        boxScore: [],
        playerStats: [
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 2,
            hits: 3,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 3,
            hits: 4,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 4,
            runs: 1,
            hits: 3,
            runsBattedIn: 0,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 2,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 2,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 0,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 4,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jim Fuchs",
            atBats: 1,
            runs: 1,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "June 16th",
        time: "7:15",
        field: 3,
        opponent: "Sluggos Sluggers",
        result: "W 14-11",
        boxScore: [],
        playerStats: [
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 3,
            runs: 2,
            hits: 2,
            runsBattedIn: 5,
            doubles: 0,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 2,
            hits: 3,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Eric Solberg",
            atBats: 3,
            runs: 1,
            hits: 2,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 2,
            runs: 1,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 3,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Rick Steinberg",
            atBats: 1,
            runs: 2,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 2,
            sac: 0,
          },
          {
            name: "Jim Fuchs",
            atBats: 2,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 1,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "June 23rd",
        time: "6:15",
        field: 1,
        opponent: "Pizza Man",
        result: "W 16-11",
        boxScore: [],
        playerStats: [
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 5,
            hits: 4,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 5,
            runs: 4,
            hits: 4,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 5,
            runs: 3,
            hits: 3,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 3,
            runs: 1,
            hits: 2,
            runsBattedIn: 4,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 4,
            runs: 0,
            hits: 3,
            runsBattedIn: 5,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 2,
            hits: 3,
            runsBattedIn: 0,
            doubles: 0,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 4,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "June 30th",
        time: "6:15",
        field: 2,
        opponent: "Nicholas & Associates",
        result: "L 13-25",
        boxScore: [],
        playerStats: [
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 3,
            runs: 2,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Mason Pingel",
            atBats: 3,
            runs: 2,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 3,
            runs: 2,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 3,
            runs: 3,
            hits: 3,
            runsBattedIn: 5,
            doubles: 0,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 3,
            runs: 1,
            hits: 3,
            runsBattedIn: 2,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Nick Vavrik",
            atBats: 3,
            runs: 0,
            hits: 3,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 2,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jim Fuchs",
            atBats: 1,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 2,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "July 7th",
        time: "7:15",
        field: 3,
        opponent: "Brookfield Blasters",
        result: "W 12-11",
        boxScore: [],
        playerStats: [
          {
            name: "Josh Serio",
            atBats: 3,
            runs: 3,
            hits: 3,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 3,
            hits: 4,
            runsBattedIn: 5,
            doubles: 1,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 2,
            runs: 2,
            hits: 2,
            runsBattedIn: 4,
            doubles: 0,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 1,
          },
          {
            name: "Steve Nelsen",
            atBats: 4,
            runs: 0,
            hits: 2,
            runsBattedIn: 0,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Andrew Bruggink",
            atBats: 3,
            runs: 1,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "July 14th",
        time: "8:15",
        field: 1,
        opponent: "Pizza Man",
        result: "L 7-17",
        boxScore: [],
        playerStats: [
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 0,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Rick Steinberg",
            atBats: 3,
            runs: 0,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 3,
            hits: 3,
            runsBattedIn: 2,
            doubles: 0,
            triples: 1,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 3,
            runs: 1,
            hits: 3,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "July 21st",
        time: "6:15",
        field: 1,
        opponent: "Brookfield Blasters",
        result: "W 12-9",
        boxScore: [],
        playerStats: [
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 2,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 2,
            runs: 2,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 2,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 4,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 2,
            runs: 3,
            hits: 1,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 2,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Eric Solberg",
            atBats: 4,
            runs: 1,
            hits: 3,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 2,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 3,
            runs: 2,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Mason Pingel",
            atBats: 4,
            runs: 2,
            hits: 3,
            runsBattedIn: 4,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Rick Steinberg",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
        ],
      },
      {
        date: "July 21st",
        time: "8:15",
        field: 1,
        opponent: "Milliman",
        result: "W 23-10",
        boxScore: [],
        playerStats: [
          {
            name: "Josh Serio",
            atBats: 5,
            runs: 3,
            hits: 3,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 5,
            runs: 4,
            hits: 4,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 5,
            runs: 5,
            hits: 5,
            runsBattedIn: 8,
            doubles: 0,
            triples: 0,
            homeruns: 3,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 1,
          },
          {
            name: "Jon Keane",
            atBats: 5,
            runs: 4,
            hits: 4,
            runsBattedIn: 2,
            doubles: 1,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 4,
            runs: 0,
            hits: 0,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Eric Solberg",
            atBats: 5,
            runs: 1,
            hits: 3,
            runsBattedIn: 5,
            doubles: 1,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 5,
            runs: 2,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 2,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Mason Pingel",
            atBats: 4,
            runs: 3,
            hits: 3,
            runsBattedIn: 2,
            doubles: 0,
            triples: 2,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Rick Steinberg",
            atBats: 1,
            runs: 1,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "July 28th",
        time: "8:15",
        field: 1,
        opponent: "Nicholas & Associates",
        result: "L 8-16",
        boxScore: [],
        playerStats: [
          {
            name: "Josh Serio",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 3,
            runs: 1,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 3,
            runs: 2,
            hits: 2,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 2,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Eric Solberg",
            atBats: 2,
            runs: 1,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Nick Vavrik",
            atBats: 3,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 3,
            runs: 1,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 2,
            runs: 0,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Rick Steinberg",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "August 4th",
        time: "6:15",
        field: 3,
        opponent: "Brookfield Blasters",
        result: "W 35-10",
        boxScore: [],
        playerStats: [
          {
            name: "Josh Serio",
            atBats: 6,
            runs: 5,
            hits: 5,
            runsBattedIn: 8,
            doubles: 0,
            triples: 0,
            homeruns: 4,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 6,
            runs: 5,
            hits: 6,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 5,
            runs: 2,
            hits: 1,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Eric Solberg",
            atBats: 4,
            runs: 3,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 2,
            sac: 0,
          },
          {
            name: "Mason Pingel",
            atBats: 6,
            runs: 5,
            hits: 6,
            runsBattedIn: 6,
            doubles: 1,
            triples: 1,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 5,
            runs: 4,
            hits: 4,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 4,
            runs: 3,
            hits: 4,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 5,
            runs: 3,
            hits: 5,
            runsBattedIn: 5,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 3,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Luke Ockwood",
            atBats: 5,
            runs: 2,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "August 4th",
        time: "8:15",
        field: 1,
        opponent: "Nicholas & Associates",
        result: "L 16-17",
        boxScore: [],
        playerStats: [
          {
            name: "Josh Serio",
            atBats: 5,
            runs: 3,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Jon Keane",
            atBats: 5,
            runs: 1,
            hits: 2,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Eric Solberg",
            atBats: 5,
            runs: 2,
            hits: 4,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 2,
            sac: 0,
          },
          {
            name: "Mason Pingel",
            atBats: 5,
            runs: 2,
            hits: 2,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 5,
            runs: 3,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 2,
            runs: 1,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 1,
          },
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 0,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Luke Ockwood",
            atBats: 4,
            runs: 0,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
    ],
  },
  {
    year: "2023", // YEAR 2023
    games: [
      {
        date: "May 4th",
        time: "8:00",
        field: 1,
        opponent: "Nicholas & Associates",
        result: "L 11-22",
        boxScore: [],
        playerStats: [
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 2,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 2,
            hits: 3,
            runsBattedIn: 4,
            doubles: 1,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 3,
            hits: 3,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 3,
            runs: 0,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 2,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 3,
            runs: 1,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 3,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Avery Goodman",
            atBats: 3,
            runs: 0,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jim Fuchs",
            atBats: 1,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "May 11th",
        time: "7:00",
        field: 2,
        opponent: "Sluggo's Sluggers",
        result: "W 10-9",
        boxScore: [],
        playerStats: [
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 3,
            hits: 4,
            runsBattedIn: 0,
            doubles: 0,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 2,
            hits: 3,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 3,
            runs: 3,
            hits: 3,
            runsBattedIn: 7,
            doubles: 0,
            triples: 0,
            homeruns: 3,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Avery Goodman",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Rick Steinberg",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jim Fuchs",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 1,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "May 18th",
        time: "8:00",
        field: 1,
        opponent: "Speedway Speedsters",
        result: "W 20-16",
        boxScore: [],
        playerStats: [
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 3,
            hits: 3,
            runsBattedIn: 2,
            doubles: 1,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 4,
            hits: 4,
            runsBattedIn: 8,
            doubles: 0,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 4,
            runs: 3,
            hits: 4,
            runsBattedIn: 2,
            doubles: 1,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 3,
            runs: 2,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Donny Baseball",
            atBats: 4,
            runs: 0,
            hits: 2,
            runsBattedIn: 2,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 2,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Avery Goodman",
            atBats: 0,
            runs: 1,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Luke Ockwood",
            atBats: 3,
            runs: 2,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Rick Steinberg",
            atBats: 3,
            runs: 3,
            hits: 3,
            runsBattedIn: 0,
            doubles: 0,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jim Fuchs",
            atBats: 2,
            runs: 1,
            hits: 1,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 1,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 3,
            runs: 0,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "May 25th",
        time: "7:00",
        field: 3,
        opponent: "Scared Hitless",
        result: "W 20-6",
        boxScore: [],
        playerStats: [
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 3,
            hits: 2,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 4,
            hits: 3,
            runsBattedIn: 3,
            doubles: 1,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 3,
            runs: 3,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 2,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 4,
            runs: 2,
            hits: 3,
            runsBattedIn: 5,
            doubles: 0,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 3,
            doubles: 0,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 2,
            sac: 0,
          },
          {
            name: "Seth Nelsen",
            atBats: 4,
            runs: 3,
            hits: 3,
            runsBattedIn: 2,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 3,
            runs: 1,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Luke Ockwood",
            atBats: 3,
            runs: 1,
            hits: 3,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Rick Steinberg",
            atBats: 4,
            runs: 0,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "June 1st",
        time: "9:15",
        field: 1,
        opponent: "Milliman",
        result: "L 10-13",
        boxScore: [],
        playerStats: [
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 2,
            hits: 3,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Mitch (Illegal)",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 4,
            doubles: 1,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 2,
            hits: 4,
            runsBattedIn: 2,
            doubles: 1,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 4,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Seth Nelsen",
            atBats: 4,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Luke Ockwood",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Austin (Illegal)",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "June 8th",
        time: "6:15",
        field: 3,
        opponent: "Thick as Thieves",
        result: "W 15-12",
        boxScore: [],
        playerStats: [
          {
            name: "Luke Ockwood",
            atBats: 4,
            runs: 0,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 3,
            hits: 3,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Josh Serio",
            atBats: 3,
            runs: 3,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 2,
            runs: 3,
            hits: 2,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 2,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 3,
            runs: 2,
            hits: 3,
            runsBattedIn: 5,
            doubles: 0,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Seth Nelsen",
            atBats: 3,
            runs: 0,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Rick Steinberg",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 1,
            hits: 3,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "June 15th",
        time: "6:15",
        field: 1,
        opponent: "Pizza Man",
        result: "L 5-9",
        boxScore: [],
        playerStats: [
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 0,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Luke Ockwood",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 2,
            hits: 3,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Seth Nelsen",
            atBats: 4,
            runs: 0,
            hits: 3,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Rick Steinberg",
            atBats: 4,
            runs: 0,
            hits: 3,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jim Fuchs",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "June 22nd",
        time: "7:15",
        field: 3,
        opponent: "Nicholas & Associates",
        result: "L 13-24",
        boxScore: [],
        playerStats: [
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 0,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 3,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Josh Serio",
            atBats: 3,
            runs: 3,
            hits: 3,
            runsBattedIn: 7,
            doubles: 1,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Mitch (Illegal)",
            atBats: 4,
            runs: 2,
            hits: 4,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 4,
            runs: 1,
            hits: 3,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jake (Illegal)",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 2,
            runs: 0,
            hits: 2,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Kelly Goodman",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jim Fuchs",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 3,
            runs: 1,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "June 29th",
        time: "7:15",
        field: 1,
        opponent: "Sluggo's Sluggers",
        result: "L 9-19",
        boxScore: [],
        playerStats: [
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 3,
            hits: 3,
            runsBattedIn: 0,
            doubles: 2,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Mitch (Illegal)",
            atBats: 4,
            runs: 2,
            hits: 4,
            runsBattedIn: 3,
            doubles: 2,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 3,
            runs: 1,
            hits: 2,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Steve Nelsen",
            atBats: 2,
            runs: 0,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Luke Ockwood",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Jason Fuchs",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Jim Fuchs",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "July 6th",
        time: "7:15",
        field: 2,
        opponent: "Speedway Speedsters",
        result: "W 16-11",
        boxScore: [],
        playerStats: [
          {
            name: "Nick Vavrik",
            atBats: 5,
            runs: 2,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jon Keane",
            atBats: 5,
            runs: 5,
            hits: 5,
            runsBattedIn: 7,
            doubles: 0,
            triples: 0,
            homeruns: 3,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 5,
            runs: 3,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Josh Serio",
            atBats: 5,
            runs: 1,
            hits: 4,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 4,
            runs: 1,
            hits: 3,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 1,
            hits: 3,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Luke Ockwood",
            atBats: 4,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jim Fuchs",
            atBats: 0,
            runs: 1,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 2,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 3,
            runs: 2,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "July 13th",
        time: "7:15",
        field: 1,
        opponent: "Scared Hitless",
        result: "W 13-12",
        boxScore: [],
        playerStats: [
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 4,
            hits: 4,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 0,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Josh Serio",
            atBats: 3,
            runs: 3,
            hits: 3,
            runsBattedIn: 7,
            doubles: 0,
            triples: 1,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Mason Pingel",
            atBats: 4,
            runs: 2,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 4,
            runs: 1,
            hits: 3,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Austin (Illegal)",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 0,
            hits: 3,
            runsBattedIn: 2,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Rick Steinberg",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "July 20th",
        time: "7:15",
        field: 3,
        opponent: "Milliman",
        result: "L 14-16",
        boxScore: [],
        playerStats: [
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 3,
            hits: 3,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 3,
            hits: 3,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Chris Barnes",
            atBats: 2,
            runs: 2,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 1,
          },
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 3,
            hits: 4,
            runsBattedIn: 5,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Eddie Edwards",
            atBats: 2,
            runs: 2,
            hits: 2,
            runsBattedIn: 3,
            doubles: 1,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 2,
          },
          {
            name: "Steve Nelsen",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 4,
            runs: 0,
            hits: 3,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 4,
            runs: 0,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Kelly Goodman",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Rick Steinberg",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "July 27th",
        time: "7:15",
        field: 1,
        opponent: "Thick as Thieves",
        result: "W 14-8",
        boxScore: [],
        playerStats: [
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 3,
            hits: 4,
            runsBattedIn: 3,
            doubles: 1,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 3,
            runs: 2,
            hits: 1,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 2,
            hits: 3,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Eddie Edwards",
            atBats: 4,
            runs: 2,
            hits: 4,
            runsBattedIn: 4,
            doubles: 1,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Jason Fuchs",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 3,
            runs: 1,
            hits: 3,
            runsBattedIn: 2,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Luke Ockwood",
            atBats: 3,
            runs: 1,
            hits: 2,
            runsBattedIn: 0,
            doubles: 1,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 2,
            runs: 1,
            hits: 1,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 1,
            sac: 0,
          },
        ],
      },
      {
        date: "August 3rd",
        time: "6:00",
        field: 2,
        opponent: "Pizza Man",
        result: "W 6-5",
        boxScore: [],
        playerStats: [
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 1,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Michael Barnes",
            atBats: 4,
            runs: 1,
            hits: 3,
            runsBattedIn: 1,
            doubles: 0,
            triples: 1,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Eddie Edwards",
            atBats: 3,
            runs: 0,
            hits: 3,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 1,
          },
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Matt Ausloos",
            atBats: 4,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 4,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Brian (Illegal)",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 3,
            runs: 0,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Luke Ockwood",
            atBats: 3,
            runs: 1,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 1,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Chris Barnes",
            atBats: 2,
            runs: 2,
            hits: 2,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
      {
        date: "August 10th",
        time: "7:00",
        field: 1,
        opponent: "Pizza Man",
        result: "W 6-5",
        boxScore: [],
        playerStats: [
          {
            name: "Nick Vavrik",
            atBats: 4,
            runs: 2,
            hits: 2,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Elijah Goodman",
            atBats: 4,
            runs: 3,
            hits: 4,
            runsBattedIn: 3,
            doubles: 0,
            triples: 0,
            homeruns: 1,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Connor (Illegal)",
            atBats: 4,
            runs: 3,
            hits: 4,
            runsBattedIn: 4,
            doubles: 1,
            triples: 1,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Josh Serio",
            atBats: 4,
            runs: 1,
            hits: 2,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Eddie Edwards",
            atBats: 3,
            runs: 1,
            hits: 3,
            runsBattedIn: 1,
            doubles: 1,
            triples: 0,
            homeruns: 2,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Steve Nelsen",
            atBats: 2,
            runs: 0,
            hits: 0,
            runsBattedIn: 1,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Jason Fuchs",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Donny Baseball",
            atBats: 3,
            runs: 0,
            hits: 1,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Luke Ockwood",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
          {
            name: "Dennis Vavrik",
            atBats: 3,
            runs: 0,
            hits: 0,
            runsBattedIn: 0,
            doubles: 0,
            triples: 0,
            homeruns: 0,
            strikeouts: 0,
            baseOnBalls: 0,
            sac: 0,
          },
        ],
      },
    ],
  },
];
