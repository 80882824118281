import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import News from "./components/News/News";
import Stats from "./components/Stats/Stats";
import Roster from "./components/Roster/Roster";
import Schedule from "./components/Schedule/Schedule";
import React, { useEffect } from "react";

import gfiLogo from "./images/gfi-logo-transparent.png";
import "./App.css";
import PlayerInfoModal from "./components/Modals/PlayerInfoModal/PlayerInfoModal";

function App() {
  const [modalPlayerInfo, setModalPlayerInfo] = React.useState(null);
  const [adModal, setAdModal] = React.useState(false);
  const [adClicked, setAdClicked] = React.useState(false);

  // function startAdTimer() {
  //   setTimeout(() => {
  //     setAdModal(true);
  //   }, 20000);
  // }

  // useEffect(() => {
  //   startAdTimer();
  // }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {adModal && (
          <div className="ad-modal">
            <div
              style={{
                position: "fixed",
                top: 10,
                right: 20,
                color: "white",
                fontSize: "40px",
                cursor: "pointer",
              }}
              onClick={() => setAdModal(false)}
            >
              X
            </div>
            <div className="ad-modal-content">
              {!adClicked ? (
                <img
                  className="news-article-image"
                  style={{ cursor: "pointer" }}
                  src={require(`./images/ad-images/jasonad.png`)}
                  alt="#"
                  onClick={() => setAdClicked(true)}
                />
              ) : (
                <div style={{ backgroundColor: "white", padding: 20 }}>
                  <span style={{ fontWeight: "600" }}>Shame on you!</span>
                  <br />
                  <br />
                  While it's important to have team chemistry, we should not be
                  pursuing teammates sexually.
                </div>
              )}
            </div>
          </div>
        )}
        <div className="header">
          <img className="gfi-logo" src={gfiLogo} alt="GFI Softball" />
          <div className="header-title-container">
            <p className="main-title">Goodman Family Insurance</p>
            <p className="secondary-title">Softball Club</p>
          </div>
          <div className="navigation">
            <Link to="news" className="neon-button">
              News
            </Link>
            <Link to="/stats" className="neon-button">
              Stats
            </Link>
            <Link to="/roster" className="neon-button">
              Roster
            </Link>
            <Link to="/schedule" className="neon-button">
              Schedule
            </Link>
          </div>
        </div>
        <div className="navigation-below">
          <Link to="news" className="neon-button">
            News
          </Link>
          <Link to="/stats" className="neon-button">
            Stats
          </Link>
          <Link to="/roster" className="neon-button">
            Roster
          </Link>
          <Link to="/schedule" className="neon-button">
            Schedule
          </Link>
        </div>

        <div className="content-container">
          <PlayerInfoModal
            playerInfo={modalPlayerInfo}
            setModalPlayerInfo={setModalPlayerInfo}
          />
          <Routes>
            <Route path="/" element={<News />} />>
            <Route path="news" element={<News />} />
            <Route path="stats" element={<Stats />} />
            <Route
              path="roster"
              element={<Roster setModalPlayerInfo={setModalPlayerInfo} />}
            />
            <Route path="schedule" element={<Schedule />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
