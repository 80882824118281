import React from "react";
import GameDate from "./GameDate/GameDate";
import { gameDates } from "../../constants/gameDates";

function Schedule() {
  const [selectedYear, setSelectedYear] = React.useState("2023");
  const [schedule, setSchedule] = React.useState([]);

  React.useEffect(() => {
    const gamesThatYear = JSON.parse(
      JSON.stringify(
        gameDates.find((games) => games.year === selectedYear).games
      )
    );
    console.log("\n gamesThatYear: ", gamesThatYear);
    setSchedule(gamesThatYear);
  }, [selectedYear]);

  function getRecord() {
    let wins = 0;
    let losses = 0;
    schedule.forEach((game) => {
      if (game.result && game.result.charAt(0) === "W") {
        wins += 1;
      }
      if (game.result && game.result.charAt(0) === "L") {
        losses += 1;
      }
    });
    return wins + "-" + losses;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "95vw",
        margin: "0vh auto 1vh auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "1vh",
          borderBottom: ".5px solid black",
          marginTop: "3vh",
        }}
      >
        <div
          style={{
            textAlign: "left",
            fontSize: "16px",
            color: "black",
          }}
        >
          Record: {getRecord()}
        </div>
        <select
          id="year"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
          style={{
            width: "75px",
            borderRadius: "5px",
            height: "20px",
            fontSize: "15px",
          }}
        >
          <option value="2023">2023</option>
          <option value="2022">2022</option>
        </select>
      </div>
      <div
        className="game-dates-container"
        style={{ height: "75vh", overflow: "scroll" }}
      >
        {schedule.map((gameDate) => (
          <GameDate
            date={gameDate.date}
            time={gameDate.time}
            field={gameDate.field}
            opponent={gameDate.opponent}
            result={gameDate.result}
            boxScore={gameDate.boxScore}
          />
        ))}
      </div>
    </div>
  );
}

export default Schedule;
