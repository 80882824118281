import { playerInfo } from "./playerInfo";

export const getPlayerStatsFromGames = (games, year) => {
  const gamesThatYear = games.find((game) => game.year === year);
  console.log("\n getPlayerStatsFromGames!!, gamesThatYear: ", gamesThatYear);
  const playerInfoCopy = JSON.parse(
    JSON.stringify(playerInfo.find((info) => info.year === year).players)
  );
  console.log("\n playerInfo!!, playerInfo: ", playerInfo);
  console.log("\n playerInfoCopy!!, playerInfoCopy: ", playerInfoCopy);
  gamesThatYear.games.forEach((game) => {
    // for each game
    game.playerStats.forEach((playerGameStat) => {
      // for each player stat of game
      const player = playerInfoCopy.find(
        (person) => person.name === playerGameStat.name
      ); // retrieve specific player object
      if (player) {
        // if player played that game
        player.games = player.games + 1; // increment games
        player.atBats = player.atBats + playerGameStat.atBats;
        player.runs = player.runs + playerGameStat.runs;
        player.hits = player.hits + playerGameStat.hits;
        player.doubles = player.doubles + playerGameStat.doubles;
        player.triples = player.triples + playerGameStat.triples;
        player.homeruns = player.homeruns + playerGameStat.homeruns;
        player.runsBattedIn = player.runsBattedIn + playerGameStat.runsBattedIn;
        player.baseOnBalls = player.baseOnBalls + playerGameStat.baseOnBalls;
        player.strikeouts = player.strikeouts + playerGameStat.strikeouts;
        player.sac = player.sac + playerGameStat.sac;
      }
    });
  });

  // for each player's info, calculate the average, on base, slugging and ops
  playerInfoCopy.forEach((player) => {
    player.average = player.atBats !== 0 ? player.hits / player.atBats : 0;
    // OBP = (Hits + Walks + Hit by Pitch) / (At Bats + Walks + Hit by Pitch + Sacrifice Flies).
    player.onBasePercentage = !(
      player.atBats === 0 &&
      player.baseOnBalls === 0 &&
      player.sac === 0
    )
      ? (player.hits + player.baseOnBalls) /
        (player.atBats + player.baseOnBalls + player.sac)
      : 0;
    // 1B + 2Bx2 + 3Bx3 + HRx4)/AB.
    player.sluggingPercentage =
      player.atBats !== 0
        ? (player.homeruns * 4 +
            player.triples * 3 +
            player.doubles * 2 +
            (player.hits - player.homeruns - player.doubles - player.triples)) /
          player.atBats
        : 0;
    player.onBasePlusSluggingPercentage =
      player.onBasePercentage + player.sluggingPercentage;
  });

  return playerInfoCopy;
};
