import React from "react";

const GameDate = ({ date, time, field, opponent, result, boxScore }) => {
  return (
    <div
      style={{
        display: "flex",
        color: "black",
        justifyContent: "space-between",
        paddingBottom: "3.5vh",
        paddingTop: "3.5vh",
        borderBottom: ".5px solid grey",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          //   flexGrow: "1",
          width: "35%",
          textAlign: "left",
        }}
      >
        <div
          style={{
            fontWeight: "800",
            fontSize: "23px",
          }}
        >
          {date}
        </div>
        <div style={{ fontSize: "14px" }}>{time}</div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          //   flexGrow: "3",
          width: "45%",
          textAlign: "left",
        }}
      >
        <div
          style={{
            fontSize: "15px",
            paddingTop: "4px",
            color: "hsl(203 100% 54%)",
          }}
        >
          vs. {opponent}
        </div>
        <div style={{ fontSize: "14px", paddingTop: "3px" }}>
          {" "}
          Field #{field}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          //   flexGrow: "1",
          width: "20%",
          textAlign: "left",
          paddingTop: "4px",
        }}
      >
        <div style={{ color: `${result.charAt(0) === "L" ? "red" : "green"}` }}>
          {result}
        </div>
        <div
          style={{
            textDecoration: "underline",
            color: "hsl(203 100% 54%)",
            cursor: "pointer",
            fontSize: "14px",
            paddingTop: "3px",
          }}
        >
          Box Score
        </div>
      </div>
    </div>
  );
};

export default GameDate;
