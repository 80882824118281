import React from "react";

const PlayerStat = ({ player, statSelected, index }) => {
  function formatPlayerStat(stat) {
    return statSelected === "average" ||
      statSelected === "onBasePercentage" ||
      statSelected === "sluggingPercentage" ||
      statSelected === "onBasePlusSluggingPercentage"
      ? parseFloat(stat).toFixed(3)
      : stat;
  }

  return (
    <div
      className="player-stat-info"
      style={{
        display: "flex",
        justifyContent: "space-between",
        height: "8.2vh",
        margin: "3px 0px",
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          className="stat-rank"
          style={{
            paddingTop: "1.5vh",
            marginRight: `${index > 8 ? "4.1vw" : "6vw"}`,
          }}
        >
          {index + 1}
        </div>
        <img
          src={require(`../../../images/players/${player.name
            .toLowerCase()
            .replace(/\s/g, "")}.jpg`)}
          className="player-image-circular"
          alt={`${player.name}`}
          style={{
            height: "2.8em",
            width: "2.8em",
            borderRadius: "50px",
            marginRight: "4vw",
          }}
        />
        <div
          className="stat-player-name"
          style={{ paddingTop: "1.5vh", fontWeight: "700" }}
        >
          {player.name}
        </div>
      </div>
      <div
        className="stat-number"
        style={{
          paddingTop: "1.5vh",
          fontSize: "20px",
          fontWeight: "700",
          color: "hsl(203 100% 54%)",
        }}
      >
        {formatPlayerStat(player[statSelected])}
      </div>
    </div>
  );
};

export default PlayerStat;
