import React from "react";
import PlayerStat from "./PlayerStat/PlayerStat";
import { gameDates } from "../../constants/gameDates";
import { getPlayerStatsFromGames } from "../../constants/calculateStats";
import "./Stats.css";

function Stats() {
  const [statSelected, setStatSelected] = React.useState(
    "onBasePlusSluggingPercentage"
  );
  const [sortedPlayerInfo, setSortedPlayerInfo] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState("2023");
  const [loading, setLoading] = React.useState(true);

  const minimumAtBats = selectedYear === "2022" ? 8 : 14;

  function sortPlayersByStat(stat, highToLow, year) {
    let sortedPlayerInfoCopy = getPlayerStatsFromGames(gameDates, year).slice();

    sortedPlayerInfoCopy.sort((a, b) =>
      highToLow ? b[stat] - a[stat] : a[stat] - b[stat]
    );

    // remove players from sort if they do not have enough AB's
    if (
      stat === "average" ||
      stat === "onBasePercentage" ||
      stat === "sluggingPercentage" ||
      stat === "onBasePlusSluggingPercentage"
    ) {
      sortedPlayerInfoCopy = sortedPlayerInfoCopy.filter(
        (player) => player.atBats >= minimumAtBats
      );
    }
    setSortedPlayerInfo(sortedPlayerInfoCopy);
    setLoading(false);
  }

  React.useEffect(() => {
    setLoading(true);
    sortPlayersByStat(statSelected, true, selectedYear);
  }, [statSelected, selectedYear]);

  function getStatText(stat) {
    switch (stat) {
      case "games":
        return "Games";
      case "atBats":
        return "At Bats";
      case "runs":
        return "Runs";
      case "hits":
        return "Hits";
      case "doubles":
        return "Doubles";
      case "triples":
        return "Triples";
      case "homeruns":
        return "Homeruns";
      case "runsBattedIn":
        return "Runs Batted In";
      case "baseOnBalls":
        return "Base On Balls";
      case "strikeouts":
        return "Strikeouts";
      case "sac":
        return "Sacrifice Flies";
      case "average":
        return "Average";
      case "onBasePercentage":
        return "On Base Percentage";
      case "sluggingPercentage":
        return "Slugging Percentage";
      case "onBasePlusSluggingPercentage":
        return "On Base Plus Slugging Percentage";
      default:
        return "";
    }
  }
  return (
    <div
      className="stats-container"
      style={{
        margin: "3vh auto",
        fontFamily: "Arial",
        fontSize: "17px",
        color: "black",
      }}
    >
      <div
        className="stat-container-vertical"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90vw",
          justifyContent: "flex-start",
        }}
      >
        <div
          className="stat-scrollbar"
          style={{
            display: "flex",
            flexDirection: "row",
            overflowX: "scroll",
            width: "90vw",
            flexWrap: "nowrap",
            paddingBottom: "1.7vh",
            color: "white",
          }}
        >
          <div
            className={`stat-scrollbar-item ${
              statSelected === "onBasePlusSluggingPercentage" && "selected"
            }`}
            onClick={() => setStatSelected("onBasePlusSluggingPercentage")}
          >
            OPS
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "average" && "selected"
            }`}
            onClick={() => setStatSelected("average")}
          >
            AVG
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "homeruns" && "selected"
            }`}
            onClick={() => setStatSelected("homeruns")}
          >
            HR
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "runsBattedIn" && "selected"
            }`}
            onClick={() => setStatSelected("runsBattedIn")}
          >
            RBI
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "runs" && "selected"
            }`}
            onClick={() => setStatSelected("runs")}
          >
            R
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "hits" && "selected"
            }`}
            onClick={() => setStatSelected("hits")}
          >
            H
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "doubles" && "selected"
            }`}
            onClick={() => setStatSelected("doubles")}
          >
            2B
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "triples" && "selected"
            }`}
            onClick={() => setStatSelected("triples")}
          >
            3B
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "games" && "selected"
            }`}
            onClick={() => setStatSelected("games")}
          >
            G
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "atBats" && "selected"
            }`}
            onClick={() => setStatSelected("atBats")}
          >
            AB
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "baseOnBalls" && "selected"
            }`}
            onClick={() => setStatSelected("baseOnBalls")}
          >
            BB
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "strikeouts" && "selected"
            }`}
            onClick={() => setStatSelected("strikeouts")}
          >
            SO
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "sac" && "selected"
            }`}
            onClick={() => setStatSelected("sac")}
          >
            SAC
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "onBasePercentage" && "selected"
            }`}
            onClick={() => setStatSelected("onBasePercentage")}
          >
            OBP
          </div>
          <div
            className={`stat-scrollbar-item ${
              statSelected === "sluggingPercentage" && "selected"
            }`}
            onClick={() => setStatSelected("sluggingPercentage")}
          >
            SLG
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1.5vh",
          }}
        >
          <select
            id="year"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            style={{
              width: "75px",
              borderRadius: "5px",
              height: "20px",
              fontSize: "15px",
            }}
          >
            <option value="2023">2023</option>
            <option value="2022">2022</option>
          </select>
          {(statSelected === "average" ||
            statSelected === "onBasePercentage" ||
            statSelected === "sluggingPercentage" ||
            statSelected === "onBasePlusSluggingPercentage") && (
            <span style={{ color: "red", fontSize: "11px" }}>
              Minimum {minimumAtBats} At Bats
            </span>
          )}
        </div>
        <div
          className="stat-display-info"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ marginBottom: "2vh", fontWeight: "600", fontSize: "14px" }}
          >
            {getStatText(statSelected)}
          </div>
          <div className="filter-by-rank-buttons" style={{ display: "flex" }}>
            <div
              className="sort-by-rank-button-up"
              onClick={() =>
                sortPlayersByStat(statSelected, true, selectedYear)
              }
            >
              ^
            </div>
            <div
              className="sort-by-rank-button-down"
              onClick={() =>
                sortPlayersByStat(statSelected, false, selectedYear)
              }
            >
              ^
            </div>
          </div>
        </div>
        <div
          className="stat-player-list-vertical"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading ? (
            <div>loading...</div>
          ) : (
            sortedPlayerInfo.map((player, index) => (
              <PlayerStat
                index={index}
                player={player}
                statSelected={statSelected}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default Stats;
