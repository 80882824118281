import React from "react";

function NewsArticle({ title, date, caption, image, content }) {
  return (
    <>
      <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        {title}
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginTop: "3px",
          marginBottom: "30px",
          fontWeight: "400",
          color: "hsl(203 100% 54%)",
        }}
      >
        {date}
      </div>
      <div
        className="image-container"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <img
          className="news-article-image"
          src={require(`../../images/article-images/${image}`)}
          style={{ borderRadius: "5px", border: ".5px solid grey" }}
          alt="#"
        />
        <div
          style={{
            color: "#2f4f4f",
            textAlign: "left",
            fontSize: "13.5px",
            marginBottom: "15px",
          }}
        >
          {caption}
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "15.5px",
          paddingBottom: "50px",
          marginBottom: "50px",
          borderBottom: "1px solid grey",
        }}
      >
        {content}
      </div>
    </>
  );
}

export default NewsArticle;
