import React from "react";
import NewsArticle from "../NewsArticle/NewsArticle";
import { newsArticles } from "../../constants/newsArticles";
import "./News.css";

const News = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        color: "black",
        width: "95%",
        margin: "3vh auto",
      }}
    >
      {newsArticles.map(({ title, date, image, caption, content }) => (
        <NewsArticle
          title={title}
          date={date}
          image={image}
          caption={caption}
          content={content}
        />
      ))}
      {/* <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        Solberg Blows Championship Game
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginTop: "3px",
          marginBottom: "30px",
          fontWeight: "400",
          color: "hsl(203 100% 54%)",
        }}
      >
        August 4th
      </div>
      <div
        className="image-container"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <img
          className="news-article-image"
          src={require(`../../images/article-images/eric-sad.png`)}
          style={{ borderRadius: "5px", border: ".5px solid grey" }}
          alt="#"
        />
        <div
          style={{
            color: "black",
            textAlign: "left",
            fontSize: "13px",
            marginBottom: "15px",
          }}
        >
          Solberg's bonehead fielding costs GFI their season
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          paddingBottom: "50px",
          marginBottom: "50px",
          borderBottom: "1px solid grey",
        }}
      >
        Runners on second and first. 2 outs. Up by 1 in the bottom of the 7th.
        If the team fields this last out then Goodman Family Insurance are
        champions. Manager Kelly Goodman eyes down the field to ensure his
        players are in good position. He notices right center fielder Eric
        Solberg is playing right outside the infield perimeter. "Eric, back up a
        few steps", Kelly Goodman shouts from the bench. "No it's okay, I know
        what I'm doing!", Solberg shouts back. Ockwood delivers the pitch and
        the ball gets hit. A line drive to the routine position for a normal
        right center fielder. Solberg sprints backwards and leaps for the
        ball... the ball bounces in and out of his glove and hits the outfield
        grass. Screams and cheers erupt from the Nicholas and Associate's bench
        as the winning run rounds third and crosses homeplate. Ball game.
        Nicholas and Associates are the 2022 Softball Champions. Stunned and
        confused, the Goodman Family Insurance team just stand there in
        disbelief. After a few moments of what felt like eternity, the silence
        within the team is disrupted. "What the FUCK are we doing!?", yells
        Dennis Vavrik. Jim Fuchs kicks bats across the dugout glaring and
        pointing at Solberg. "You fucked this up, Eric. You did!". Kelly Goodman
        just stands there with his arms crossed staring at his feet, knowing
        this was probably his last chance at ever winning another championship.
        The team eventually walks off the field to pack their gear to leave, but
        Solberg stays put in the outfield. He knows what's coming to him once he
        gets in the parking lot. In the distance are superfans Avery Goodman and
        Stoney who lost their bets on the game. They are enraged and await for
        Solberg with clenched fists. "Fuck that guy", Avery says. Stoney remains
        speechless with his face redder than the Florida tan on his skin.
        Solberg decides to hop the outfield fence and uber home without saying
        goodbye. "D1 my ass", Steinberg says. To add insult to injury, this also
        was Jason Fuch's last game of his career. Josh Serio, who had a very
        serious sexual relationship with Jason earlier in the season pleads him
        to stay: "Jason, we need you to come back next year to have another shot
        at winning". Jason replies, "No, dude. Just no. I'm done". MVP Elijah
        Goodman couldn't bear listening to Jason's retirement confirmation and
        started running to the porta potty with tears dripping down his cheeks.
        Concerned friend and teammate Jon Keane observes this and follows him
        inside to comfort him. The team takes a deep sigh accepting that the
        team will no longer be the same thanks to Solberg. They say their
        goodbyes and part their ways concluding the 2022 softball season. The
        only positive emotion was coming from first basemen Nick Vavrik who was
        smiling. "I hit the ball hard today and that's all you can do", he says.
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        Pizza Man delivered L to GFI
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginTop: "3px",
          marginBottom: "30px",
          fontWeight: "400",
          color: "hsl(203 100% 54%)",
        }}
      >
        July 14th
      </div>
      <div
        className="image-container"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <img
          className="news-article-image"
          src={require(`../../images/article-images/steve and jason.png`)}
          style={{ borderRadius: "5px" }}
          alt="#"
        />
        <div
          style={{
            color: "lightgrey",
            textAlign: "left",
            fontSize: "13px",
            marginBottom: "15px",
          }}
        >
          Jason Fuchs and Steve Nelsen bonding in the 1 and 2 spot
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          paddingBottom: "50px",
          marginBottom: "50px",
          borderBottom: "1px solid grey",
        }}
      >
        Goodman Family Insurance got its ass kicked by Pizza Man in a game that
        meant nothing. The biggest highlight of the game was Jason Fuchs and
        Steve Nelson sharing a special moment where Jason thanked Steve for
        being the father figure to him that he never had growing up. Jason said
        “I barely even know my dad and to be honest at this point in my life I
        don’t think I even want to meet him. I have Steve and Steve is the only
        daddy I need”. Jason was promoted to lead off for the game and went an
        INCREDIBLE 2-4! His Father figure Steve went 1-4 saying “I am saving it
        for the playoffs”. Nick almost got two hits! Going a very impressive
        1-4. Slick Rick went 2-3 shutting up all his haters. Dennis had a tough
        game at the plate but was phenomenal socially. Manager and Matt Ausloos
        ex-lover KG went 2-4. Josh Serio went 3-4 and was looking a little bit
        fatter than usual. Matt Ausloos went 2-4 and made the last out of the
        game. Elijah went 3-3. Jon Keane went 1-3 and had poor outfield play.
        Goodman Family Insurance continues it’s season next week as the PLAYOFFS
        start. They will be playing the Brookfield Blasters which Jim Fuchs says
        is the Sexiest team in the league.
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        GFI Gets Revenge on Young Men
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginTop: "3px",
          marginBottom: "30px",
          fontWeight: "400",
          color: "hsl(203 100% 54%)",
        }}
      >
        July 7th
      </div>
      <div
        className="image-container"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <img
          className="news-article-image"
          src={require(`../../images/article-images/sorry-no-image.jpeg`)}
          style={{ borderRadius: "5px" }}
          alt="#"
        />
        <div
          style={{
            color: "lightgrey",
            textAlign: "left",
            fontSize: "13px",
            marginBottom: "15px",
          }}
        >
          No meme this week because we are busy with other jobs
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          paddingBottom: "50px",
          marginBottom: "50px",
          borderBottom: "1px solid grey",
        }}
      >
        Goodman Family Insurance won their game last week Thursday by one run.
        It was a good win!
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        Lost big time with Mason
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginTop: "3px",
          marginBottom: "30px",
          fontWeight: "400",
          color: "hsl(203 100% 54%)",
        }}
      >
        June 30th
      </div>
      <div
        className="image-container"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <img
          className="news-article-image"
          src={require(`../../images/article-images/sorry-no-image.jpeg`)}
          style={{ borderRadius: "5px" }}
          alt="#"
        />
        <div
          style={{
            color: "lightgrey",
            textAlign: "left",
            fontSize: "13px",
            marginBottom: "15px",
          }}
        >
          No meme this week because we are busy with other jobs
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          paddingBottom: "50px",
          marginBottom: "50px",
          borderBottom: "1px solid grey",
        }}
      >
        No article for this week because Elijah is taking his sweet ass time
        making Tik Toks
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        HUGE win but at what cost?
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginTop: "3px",
          marginBottom: "30px",
          fontWeight: "400",
          color: "hsl(203 100% 54%)",
        }}
      >
        June 23rd
      </div>
      <div
        className="image-container"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <img
          className="news-article-image"
          src={require(`../../images/article-images/sorry-no-image.jpeg`)}
          style={{ borderRadius: "5px" }}
          alt="#"
        />
        <div
          style={{
            color: "lightgrey",
            textAlign: "left",
            fontSize: "13px",
            marginBottom: "15px",
          }}
        >
          No game meme, see why below
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          paddingBottom: "50px",
          marginBottom: "50px",
          borderBottom: "1px solid grey",
        }}
      >
        Do you want the good news or the bad news first? The good news is
        Goodman Family Insurance had a HUGE win against Pizza man. The bad news?
        Jason Fuchs left the game livid due comments made towards him during his
        last at bat of the game. Josh Serio once again wins player of the game.
        This is the fifth straight week he has given himself player of the game
        honors. This week it was very well deserved as he went 4-4 with two
        bombs. Josh had this to say after the game “Listen, winning is great and
        hitting home runs is great but today is bigger than softball.. today is
        about Jason’s feelings. That’s the only thing I care about today. Jason,
        I love you and I am sorry for what happened and for calling you a bitch.
        PLEASE FORGIVE ME”. Nick Vavrik had this to say about Jason leaving the
        field upset, “I love Jason and I am praying for him. I love him like a
        brother and I wish him nothing but the best." Team super fan Stoney was
        asked about his thoughts on Josh’s abilities at shortstop and had this
        to say, “Only thing on my mind today is Jason. I hope Jason is ok. I
        love that boy. He is like a son to me”. Steve Nelson was asked how his
        arm was feeling after the game and said, “Softball is secondary today.
        Only thing I care about is Jason and how he is feeling. I love him”.
        Manager Kelly Goodman had this to say after the game, “Huge win but
        overall, it was a horrible night. Jason left the game upset, and I am
        sick to my stomach. I love you Jason”. LF Elijah Goodman had this to say
        after the team’s huge win, “I forgot there was even a game today. The
        only thing on my mind right now is Jason. He left the park upset today
        and that doesn’t sit to well with me. I love Jason”. Matt Ausloos was
        asked about Nick’s ability at first base and had this to say, “Seeing
        Jason leave the game so upset ruined my entire week. I love Jason and
        would do anything to see him smile". D Vavrik had this to say about
        Steve’s pitching, “I don’t care about Steve’s pitching, only thing on my
        mind today is Jason’s feelings. I want to see him happy. I love Jason”.
        Jon Keane declined to comment on the game as he said, “Softball is
        secondary. Tonight is about Jason”. Goodman Family Insurance continues
        it season next week against the only undefeated team in the league but
        all the team cares about is Jason showing up with a SMILE on his face!
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        GFI Cheats Again
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginTop: "3px",
          marginBottom: "30px",
          fontWeight: "400",
          color: "hsl(203 100% 54%)",
        }}
      >
        June 17th
      </div>
      <div
        className="image-container"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <img
          className="news-article-image"
          src={require(`../../images/article-images/kgmeeting.png`)}
          style={{ borderRadius: "5px" }}
          alt="#"
        />
        <div
          style={{
            color: "lightgrey",
            textAlign: "left",
            fontSize: "13px",
            marginBottom: "15px",
          }}
        >
          Manager Kelly Goodman continues his bad habits
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          paddingBottom: "50px",
          marginBottom: "50px",
          borderBottom: "1px solid grey",
        }}
      >
        Goodman Family Insurance once again used an illegal player to help them
        win. This week’s illegal player was former D1 baseball player Eric
        Solberg who was the game hero with a walk off 2 run bomb! Despite all
        the cheating allegations against the team, manager Kelly Goodman had
        this to say, “I have not, nor will I EVER use illegal players. I promise
        you that”. After the game there was a quick roster check and it was
        proven that Eric Solberg was in fact not on the roster and an illegal
        player. Kelly has many cheating allegations against him during his time
        as manager from illegal players to illegal bats to supplying former
        leadoff hitter Jason Fuchs with steroids. Luckily for manager Kelly
        Goodman, his best friend and teammate is attorney at law Rick Steinberg
        who is there for all legal issues that arise. Rick had this to say, “You
        need to understand I am very good at what I do, and I make more money
        than you could ever dream of”. Along with all his success off the field,
        attorney at law Rick Steinberg also had one of the biggest hits of the
        night. Goodman Family Insurance was down 4 runs going into the bottom
        half of the last inning. Rick started off the inning with a classic
        slick rick single with a hit over the second basemen’s head. KG followed
        with a line drive single over the LCF head. Josh kept the rally going
        with a line shot single which set the stage for Elijah to hit a game
        tying homerun. Which then led to Matt getting on base with a single
        which set the stage for illegal player Eric Solberg to hit a walk off 2
        run home run! Eric had this to say post game, “I don’t know who the 7
        hitter is but oh my god does he hit the ball hard. I have played a lot
        of softball and baseball in my life and I have never ever seen anyone
        hit the ball as hard as that guy”. Notable players of the game were
        Elijah, Matt, Eric, Steve, Slick, Jim, and KG. All other players
        ***cough cough*** Jason, Nick, and Donny did not come to play and need
        to be better. Josh was decent but not great. Goodman Family Insurance
        continues it’s season next week
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        Behind the Uniform: Jon Keane
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginTop: "3px",
          marginBottom: "30px",
          fontWeight: "400",
          color: "hsl(203 100% 54%)",
        }}
      >
        June 13th
      </div>
      <div
        className="image-container"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <img
          className="news-article-image"
          src={require(`../../images/article-images/jon-happy.jpg`)}
          style={{ borderRadius: "5px" }}
          alt="#"
        />
        <div
          style={{
            color: "lightgrey",
            textAlign: "left",
            fontSize: "13px",
            marginBottom: "15px",
          }}
        >
          Jon Keane, OF
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          paddingBottom: "50px",
          marginBottom: "50px",
          borderBottom: "1px solid grey",
        }}
      >
        In this week’s article of getting to know the person behind the uniform
        we sat down and talked with team superstar Jon Keane to get to know him
        a little bit better. Jon is a 25-year-old straight male who currently
        resides in the town of Brookfield. During the week Jon works as an
        account manager at Global Industrial in Menomonee Falls. He has been
        with the company for roughly 18 months. He has had a lot of success in
        his current role hitting his quarterly sales goal more times than not!
        Jon really enjoys his co-workers and his overall work environment.
        Outside of work Jon enjoys working out, golf, bible study, and spending
        time with his friends and family. Jon comes from a very large family.
        Jon is the oldest of five kids as he has four younger sisters. Jon has
        two very loving parents Ryan and Beth who he admires dearly. Jon would
        define himself as a family man and hopes to have a family of his own one
        day. On the field Jon has been a key contributor for the past three
        seasons. He is a great outfielder but more importantly he is a
        phenomenal hitter! He has power and can hit for average. He is routinely
        at the top of the team and entire league for home runs and batting
        average. His softball abilities are not surprising as he was an
        all-conference baseball player in high school and a collegiate athlete
        at Calvin College in Michigan and Azuza Pacific University in
        California. Jon played short stop and pitcher at Calvin College and was
        a pitcher only at Azuza Pacific. In his prime Jon could get his fastball
        up to 88 MPH!! Jon is looking forward to finishing this season on a high
        note and enjoying his summer with his family and friends.
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        Oh No, We Suck Again!
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginTop: "3px",
          marginBottom: "30px",
          fontWeight: "400",
          color: "hsl(203 100% 54%)",
        }}
      >
        June 9th
      </div>
      <div
        className="image-container"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <img
          className="news-article-image"
          src={require(`../../images/article-images/jimplayers.png`)}
          style={{ borderRadius: "5px" }}
          alt="#"
        />
        <div
          style={{
            color: "lightgrey",
            textAlign: "left",
            fontSize: "13px",
            marginBottom: "15px",
          }}
        >
          Jim Fuchs shared his admiration towards the other team
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          paddingBottom: "50px",
          marginBottom: "50px",
          borderBottom: "1px solid grey",
        }}
      >
        Thursday night was not a good night for Goodman Family Insurance as they
        lost to the 1-3 Brookfield Blasters. With a win, Goodman Family
        Insurance would have bounced back to .500 and jump into third place in
        the league standings. But thanks to an 0-4 performance by Jason Fuchs
        and a bases loaded ground out by Nick Vavrik, Goodman Family Insurance
        came up short. Jason had this to say post game, “If I tried I would’ve
        got a hit but I just didn’t try. I don’t know, I just don’t have fun
        playing so I didn’t want to try”. Nick had this to say after his
        groundout with the bases loaded late in the game, “That was a really
        hard hit grounder. Really hard hit. I got all of it. Nothing I can do if
        I hit it right at someone”. Nick and Jason continue to work hard to
        improve their batting averages so they can move up from the 7 and 8
        spots in the lineup. Josh Serio made it clear post game that he should
        be player of the game due to his two home runs and great infield
        performance. This is the 5th week in a row Josh has voted himself player
        of the week. Congrats Josh! Donny Baseball and Steve continued their
        strong seasons both hitting .500 on the day. Unfortunately, Jason,
        Dennis, Jim, and KG went a combined 0-11 on the day. Jim had an
        unusually strong interest in the opposing team and had this to say post
        game: "It was hard for me to focus. Watching these young men run and
        giggle really got to me. I had the pleasure to meet a few of them after
        the game which was really cool. I look forward to playing them again
        this season." There is no question this was a very disappointing loss,
        but manager Kelly Goodman says we need to forget this one and move on.
        “Right now, Matt Ausloos is in the three spot and has no power. I never
        liked Matt. I hate Matt. But Matt is on the team still. If it was up to
        me, I would have Matt shot and killed. I hate Matt so much. What was
        your question again?” Kelly Goodman was not the only one to have an
        issue with a teammate on the day. There were rumors Jason Fuchs
        committed to a post-game dinner with his good friend Elijah Goodman and
        bailed last second but we are still waiting on confirmation. Goodman
        Family Insurance continues it’s season next week against Sluggos.
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        The 'Last Dance' for Jason Fuchs?
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginTop: "3px",
          marginBottom: "30px",
          fontWeight: "400",
          color: "hsl(203 100% 54%)",
        }}
      >
        June 2nd
      </div>
      <div
        className="image-container"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <img
          className="news-article-image"
          src={require(`../../images/article-images/last-dance.png`)}
          style={{ borderRadius: "5px" }}
          alt="#"
        />
        <div
          style={{
            color: "lightgrey",
            textAlign: "left",
            fontSize: "13px",
            marginBottom: "15px",
          }}
        >
          Jason Fuchs is hanging up the cleats after his 8th season
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          paddingBottom: "50px",
          marginBottom: "50px",
          borderBottom: "1px solid grey",
        }}
      >
        Jason Fuchs made some shocking comments before the first pitch of
        yesterday's game as he said, “This is probably my last season. I just
        don’t like it that much anymore”. Many believe these comments had a lot
        to do with his spot in the batting order as Jason thoroughly enjoys
        softball when he is hitting lead off. Either way, if this is it for
        Jason, you couldn’t ask for a better game to go out on. Goodman Family
        Insurance won against Milliman due to a heroic comeback and HUGE hits by
        Nick Vavrik and Josh Serio. Nick who was livid pregame due to his
        demotion in the line up came up with one of the biggest hits of the
        season as he came to the plate with the bases loaded and two outs in the
        bottom of the 7th inning, down three… with the game on the line, he
        launched a deep fly ball to right center for a bases clearing triple to
        tie the game. Matt Liewen came up to bat with a chance to walk it off in
        the bottom of the 7th but failed to do so as he had a deep flyout to the
        pitcher. Steve Nelsen was lights out down the stretch and kept Milliman
        scoreless in the top of the 8th. The bottom of the 8th inning started
        with line shot singles from Jason Fuchs and Kelly Goodman. Which set the
        stage for Josh Serio who previously shit the bed twice with two pop outs
        with the bases loaded in his previous two at bats. With a chance for
        redemption, Josh hit a line shot single that got past the outfielder
        which allowed Jason Fuchs to score the game winning run for Goodman
        Family Insurance. Josh got emotional after the game saying, “This one
        was for my daddy. My whole life he’s been there supporting me and to
        come through in a moment like this... I don’t even know man. I love you,
        daddy”. Jason had this to say about the big win: “The inflation rate is
        the highest it's been in over 40 years”. Manager Kelly Goodman had this
        to say, “This was probably the best day of my life. I love my kids and
        the day they were born was cool but I don’t know man... today, today was
        special man”. After the huge win Goodman Family Insurance moves to a
        season record of 2-3. A win next week would put them at 3-3.
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        Serio Goes 3 for 3!!!!!
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginTop: "3px",
          marginBottom: "30px",
          fontWeight: "400",
          color: "hsl(203 100% 54%)",
        }}
      >
        May 26th
      </div>
      <div
        className="image-container"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <img
          className="news-article-image"
          src={require(`../../images/article-images/kg-diving-catch.png`)}
          style={{ borderRadius: "5px" }}
          alt="#"
        />
        <div
          style={{
            color: "lightgrey",
            textAlign: "left",
            fontSize: "13px",
            marginBottom: "15px",
          }}
        >
          Kelly Goodman laid it all on the line for his team
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          paddingBottom: "50px",
          marginBottom: "50px",
          borderBottom: "1px solid grey",
        }}
      >
        Goodman Family Insurance had a great showing last night in the top of
        the 6th inning as they scored two runs on a Jon Keane home run and Steve
        Nelsen line shot single. Nicholas and Associates pitcher was on his
        A-Game last night shutting down Goodman Family Insurance through the
        first 5 innings. He was spotting the ball perfectly and mixing speeds.
        There was nothing Goodman Family Insurance could do when a pitcher is
        rolling like that... you just have to tip your hat. Jason Fuchs had this
        to say post game, “Listen, I know I am hitting .150 this year but I can
        hit. That pitcher tonight... he was special. I had no chance against
        him, I was overmatched. I haven’t seen a pitcher spot up like that in a
        long time. Just fun to watch when a pitcher is dealing like that”. Donny
        Baseball also had similar things to say about Nicholas and Associates
        pitcher, “Oh my god, that was the greatest pitching performance I have
        ever seen! The ball was here, there, and everywhere. I had no chance. I
        was honestly scared to have to go hit. That pitcher was phenomenal”.
        Nick Vavrik went 0-3 on the day but made it clear to everyone in the
        dugout that he hit the ball hard every at bat and it was a very unlucky
        0-3! Elijah went a pathetic 1-3 and failed to run to 1st base on his
        pathetic ground out to short stop. Can you say washed up? KG went 0-2.
        Jason went 0-2. Ausloos who is a covid survivor went 1-3. Donny went 1-3
        but was scared of the pitcher. Josh Serio went 3-3 and gave himself the
        player of the game award in the parking lot post game. (Well deserved!)
        Matt Liewen made his debut going 1-3 but looked very strong thanks to
        his rigorous workout routine at the WAC. Goodman Family Insurance looks
        to get in the win column next week and hold the opposing team to under 7
        runs in the first inning. Also, a HUGE SHOUTOUT to KELLY GOODMAN for his
        amazing plays in right field last night. It was one of the greatest
        fielding performances in Wirth Park history. Congrats KG!!!! YOU ARE
        AMAZING!!!!!!!
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        Serio Blows The Game
      </div>
      <div
        style={{
          textAlign: "left",
          fontSize: "14px",
          marginTop: "3px",
          marginBottom: "30px",
          fontWeight: "400",
          color: "hsl(203 100% 54%)",
        }}
      >
        May 19th
      </div>
      <div
        className="image-container"
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
        }}
      >
        <img
          className="news-article-image"
          src={require(`../../images/article-images/donny-pizza.png`)}
          style={{ borderRadius: "5px" }}
          alt="#"
        />
        <div
          style={{
            color: "lightgrey",
            textAlign: "left",
            fontSize: "13px",
            marginBottom: "15px",
          }}
        >
          Pizza man too hot for GFI to handle
        </div>
      </div>
      <div
        style={{ textAlign: "left", fontSize: "14px", paddingBottom: "50px" }}
      >
        Down by three runs in the bottom of the sixth inning Josh Serio came to
        the plate with an opportunity to tie the game. However, he flew out to
        left field on a very softly hit fly ball. When asked about that at bat
        post game Serio said, “That one hurts. Shit man, I let the team down. I
        need to be better, and I will”. Serio missed the first two games of the
        season due to reasons unknown. Goodman Family Insurance fell to Pizza
        Man after giving up 15 runs in the first inning. Manager Kelly had this
        to say: “Fuck, Fuck, Fuck”. After the interview, Kelly sprinted directly
        at leadoff hitter Jason Fuchs where the two started passionately making
        out in front of Jason’s father Jim. Steve Nelsen was livid with the
        defensive support he received throughout the game but remained positive
        stating, “I love this team more than my own family. There isn’t a thing
        I wouldn’t do for this team”. Steve also finished the game with 3 hits.
        Matt Ausloos was unable to play in Thursday’s game due to Covid which
        had teammate Jason Fuchs livid. Almost as livid as Jason was post game
        in the parking lot when his father Jim gave him the bucket of balls to
        put by KG’s car. Top performers of the day were Elijah going 4-4 with 4
        pathetic singles and Steve going 3-4. Jon Keane hit a grand slam due to
        the wind blowing straight out, on a normal day it is a routine fly out.
        Jon finished the game going 1-4. Yes, you read that right, Jon Keane
        went 1-4. Once again Jon went 1-4. Goodman Family continues it’s season
        next week Thursday as they will try to get back to .500 on the season!
      </div> */}
    </div>
  );
};

export default News;
